//Node Modules
import { Link } from "react-router-dom";

//Project Files
import logo from "../assets/logo.png";

export default function Navbar() {
  return (
    <nav className="navbar">
      <Link className="logo-holder" to="/">
        <img className="logo" src={logo} alt="Logo of dynomatic" />
      </Link>
      <div className="links">
        <Link to="/about">ABOUT US</Link>
        <Link to="/contact">CONTACT US</Link>
      </div>
    </nav>
  );
}
