//Node Modules
import { Routes, Route } from "react-router-dom";

/*Project Files*/
import "./styles/styles.css";
import Navbar from "./components/Navbar";

import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<div>This page does not exist!!</div>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
