//Node modules
import { useNavigate } from "react-router-dom";

export default function BrandCard({ logo, cssclass }) {
  const navigate = useNavigate();
  function handleLogoClick() {
    navigate("/contact");
  }
  return (
    <article className="brand-card">
      <button onClick={() => handleLogoClick()}>
        <img className={cssclass} src={logo} alt="A logo" />
      </button>
    </article>
  );
}
