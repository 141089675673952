// import dubai from "../assets/dubai_large.jpg";
export default function About() {
  return (
    <div>
      <section className="img-holder">
        <div className="blurred">
          <p>
            Dynomatic Telecom is a Wholesale Supplier of Telecommunication
            products as well as consumer electronics.
          </p>
        </div>
      </section>
      <section className="details">
        <p>
          We are based in Dubai, United Arab Emirates.
          <br />
          We are one of the leading suppliers of multi-brand walkie talkies and
          their accessories.{" "}
        </p>
      </section>
    </div>
  );
}
