import Hero from "../components/Hero";
import Brands from "../sections/Brands";
export default function Home() {
  return (
    <div>
      <Hero />
      <Brands />
    </div>
  );
}
