import hero from "../assets/hero.jpg";
import arrow from "../assets/arrow.png";
import whatsapp from "../assets/whatsapp.png";
export default function Hero() {
  return (
    <main className="hero-holder">
      <img className="hero" src={hero} alt="Earth as seen from space" />
      <h1>
        Find the best collection of radio communication devices from a wide
        range of brands here.
        <button
          onClick={() => {
            let pageHeight = window.innerHeight;
            window.scrollBy(0, pageHeight);
          }}
        >
          {" "}
          <img className="arrow" src={arrow} alt="An arrow" />
        </button>
      </h1>
      <a className="sticky" href="https://wa.me/971529150915">
        <img
          className="sticky-whatsapp"
          src={whatsapp}
          alt="An icon of whatsapp"
        />
      </a>
    </main>
  );
}
