import BrandCard from "../components/BrandCard";
import hytera from "../assets/hytera.png";
import bao from "../assets/bao.png";
import codan from "../assets/codan.png";
import furuno from "../assets/furuno.png";
import icom from "../assets/icom.png";
import tyt from "../assets/tyt.jpeg";
import yaesu from "../assets/yaesu.png";
import motorola from "../assets/motorola.png";
import sepura from "../assets/sepura.png";
import simrad from "../assets/simrad.png";
import garmin from "../assets/garmin.png";
import kenwood from "../assets/kenwood.png";

export default function Brands() {
  return (
    <section className="brands-section">
      <BrandCard logo={hytera} cssclass={"hytera"} />
      <BrandCard logo={bao} cssclass={"bao"} />
      <BrandCard logo={codan} cssclass={"codan"} />
      <BrandCard logo={furuno} cssclass={"furuno"} />
      <BrandCard logo={icom} cssclass={"icom"} />
      <BrandCard logo={tyt} cssclass={"tyt"} />
      <BrandCard logo={yaesu} cssclass={"yaesu"} />
      <BrandCard logo={motorola} cssclass={"motorola"} />
      <BrandCard logo={sepura} cssclass={"sepura"} />
      <BrandCard logo={simrad} cssclass={"simrad"} />
      <BrandCard logo={garmin} cssclass={"garmin"} />
      <BrandCard logo={kenwood} cssclass={"kenwood"} />
    </section>
  );
}
