//Node Modules
import React, { useState, useRef, useEffect } from "react";
import { sendEmail } from "../scripts/sendEmail";
import whatsapp from "../assets/whatsapp.png";

export default function Form() {
  const [success, setSuccess] = useState(false);
  const form = useRef();

  function handleSubmit(e) {
    e.preventDefault();
    sendEmail(e);
    setSuccess(true);
    e.target.reset();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact-page">
      <form
        ref={form}
        className="contact-form flex-column-center"
        onSubmit={(e) => handleSubmit(e)}
      >
        <h2 className="main-heading">CONTACT US FOR THE BEST QUOTE.</h2>
        <div className="label">
          <span>Name</span>
          <span className="red">*</span>
        </div>
        <input
          type="text"
          name="name"
          required
          placeholder="Example: John Doe"
        />
        <div className="label">
          <span>Company</span>
          <span className="red">*</span>
        </div>
        <input
          type="text"
          name="company"
          required
          placeholder="Example: Dolby Computers LLC"
        />
        <div className="label">
          <span>Email</span>
          <span className="red">*</span>
        </div>
        <input
          type="email"
          name="email"
          required
          placeholder="Example: johndoe@mail.com"
        />
        <div className="label">
          <span>Phone</span>
          <span className="red">*</span>
        </div>
        <input
          type="text"
          name="mobile"
          required
          placeholder="Example: +12 0123456781"
        />
        <div className="label">
          <span>Message</span>
          <span className="red">*</span>
        </div>
        <textarea
          type="text"
          name="message"
          required
          placeholder="Please leave a short message about your requirements."
        ></textarea>
        {success && (
          <span className="success-message">
            Thank you for contacting us. We have received your query. We will
            get back to you soon!!.
          </span>
        )}
        <input type="submit" />
      </form>
      <section className="whatsapp-section">
        <h2>Chat with us</h2>
        <a href="https://wa.me/971529150915">
          <img className="whatsapp" src={whatsapp} alt="An icon of whatsapp" />
        </a>
      </section>
      <section className="address-section">
        <h2>Address</h2>
        <p>Dynomatic Telecom Trading Co. LLC,</p>
        <p>Al Dallal Building, 1st Floor,</p>
        <p>Behind Passenger Hotel,</p>
        <p>Deira - Dubai</p>
      </section>
      <section className="hours-section">
        <h2>Working hours</h2>
        <p>Friday</p>
        <p>09:00 - 12:00 & 17:00 - 20:00</p>
        <p>Monday - Thursday & Saturday</p>
        <p>09:00 - 13:00 & 17:00 - 20:00</p>
      </section>
    </div>
  );
}
